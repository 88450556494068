import React,{useState,useEffect}from 'react'
import './App.css';
import axios from 'axios';
import styled from "styled-components";
import { Buffer } from 'buffer'
function Chat() {
  const [url, setURl] = useState('')
  const [title, setTitle] = useState('')
  const [mimeType, setmimeType] = useState('https://storage.googleapis.com/download/storage/v1/b/billbuds-39e42.appspot.com/o/%2Fuploads%2Fhashnode%2Fgiffy?generation=1650462131855790&alt=media')
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [trigger1, setTrigger1] = useState(false)
  const [trigger2, setTrigger2] = useState(false)
  const [trigger3, setTrigger3] = useState(false)
  const [trigger4, setTrigger4] = useState(false)
  const [trigger5, setTrigger5] = useState(false)
  const [trigger6, setTrigger6] = useState(false)
  useState(()=>{
    setTimeout(()=>{
      setTrigger1(true)
     },[700])
     setTimeout(()=>{
      setTrigger2(true)
     },[1400])
     setTimeout(()=>{
      setTrigger3(true)
     },[2100])
     setTimeout(()=>{
      setTrigger4(true)
     },[2800])
     setTimeout(()=>{
      setTrigger5(true)
     },[3500])
     setTimeout(()=>{
      setTrigger6(true)
     },[4200])
  
  setTimeout(()=>{
    setTrigger1(false)
    setTrigger2(false)
    setTrigger3(false)
    setTrigger4(false)
    setTrigger5(false)
    setTrigger6(false)
     },[10000])

setInterval(()=>{
  setTimeout(()=>{
    setTrigger1(true)
   },[700])
   setTimeout(()=>{
    setTrigger2(true)
   },[1400])
   setTimeout(()=>{
    setTrigger3(true)
   },[2100])
   setTimeout(()=>{
    setTrigger4(true)
   },[2800])
   setTimeout(()=>{
    setTrigger5(true)
   },[3500])
   setTimeout(()=>{
    setTrigger6(true)
   },[4200])

setTimeout(()=>{
  setTrigger1(false)
  setTrigger2(false)
  setTrigger3(false)
  setTrigger4(false)
  setTrigger5(false)
  setTrigger6(false)
   },[10000])

},[10000])
  },[trigger1])

  const posting= async()=>{
    setFetching(true)
    setLoading(true)
    const res = await axios.post('http://localhost:5000/getit', {
    url
   });
   console.log(res.data)
   console.log(res.data)
   setTitle(res.data.Title)
   const mimeType = res.data.newurl
   setmimeType(mimeType)
   setLoading(false)
   setFetching(false)
  }
  return (
    <div className="Chat">
    {/* <div className="searchBar">
              <input onChange={(e) => setURl(e.target.value)}type="text" id="search" name="search" placeholder='Type in a url'/>
              <div onClick={posting} className="srch-Btn"><p style={{fontSize:'15px',marginBottom:'15px'}}>Search</p></div>
        </div> */}
<Animate triggers={trigger1}className="send">Hey there! I need some clothes</Animate>
<Animate triggers={trigger2}className="receive">What style are you going for </Animate>
<Animate triggers={trigger3}className="send">Some classy for a date </Animate>
{fetching&&<img src='https://media0.giphy.com/media/QvjmxpTkWK6ty5DTJY/giphy.gif?cid=790b7611ad857fc7024e3d85994180c31dcf7073ee531fbe&rid=giphy.gif&ct=g'/>}
{!loading&& <>
<Animate triggers={trigger4}className=" gif"><img style={{width:250}}src={mimeType} /></Animate>
<Animate triggers={trigger5}className="receive ">I think this is a good fit for you. {title} 👉 <a target="_blank"href={url} rel="noreferrer">see more</a> </Animate>
 <Animate triggers={trigger6}className="send">Wow this is awesome how can i get it !</Animate>
{/* <p className="receive">I know right you can get it over <a href={url} target="_blank" rel="noreferrer">here</a> </p> */}
</>
}
    </div>
  );
}
const Animate =styled.p` 
 transform:${props => props?.triggers?"scale(1);":'scale(0);'};
 transition ease-in-out 0.3s;
}
`
export default Chat;
