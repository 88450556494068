import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDqf0AyysNl4XBb3s4r7sTzllYXDFyxXik",
  authDomain: "sugar-01.firebaseapp.com",
  projectId: "sugar-01",
  storageBucket: "sugar-01.appspot.com",
  messagingSenderId: "714771325918",
  appId: "1:714771325918:web:4a702bc0cb19970339334f",
  measurementId: "G-38GDXQ2RHV"
};
const app = initializeApp(firebaseConfig);
export const authentication= getAuth(app);
