import React from 'react'
import Logo from './logo.svg'
import styled from "styled-components";
import buttonBackground from './buttonBackground.svg'
function Header() {
  return (
    <Head> 
       <img src={Logo}/>
       {/* <CTA>
       <HeadButton>
        <p>Text us</p>
       </HeadButton>
        <img src={buttonBackground}/>
       </CTA> */}
    
      
    </Head>
  )
}
const HeadButton= styled.div`
  color: #fafafa;
  background: linear-gradient(
    90deg,
    hsla(339, 100%, 55%, 1) 0%,
    hsla(197, 100%, 64%, 1) 100%
  ); transition: all 1000ms ease-in-out;
 
  &:hover {
    transition: all 1000ms ease-in-out;
    background: linear-gradient(90deg, hsla(197, 100%, 63%, 1) 0%, hsla(294, 100%, 55%, 1) 100%);
  }
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 20;
  & > p {
  font-family: Recolata-Regular;
  font-size:15px;
  background: rgb(117, 74, 88);
  height: 20px;
  padding: 10px 30px;
  border-radius: 5px;
  transition: all 1000ms ease-in-out;
  @media only screen and (max-width: 1200px) {
    /* background: #24243a; */
    padding: 10px 15px;
  }
}
`
const Head = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 90%;
margin: 20px auto;
@media only screen and (max-width: 800px) {
  & > img {
  width:150px;
  z-index:20;
}
}
`
const CTA = styled.div`
   z-index:20;
   position:relative;
   & > img{
    position:absolute;
    left:20px;
    @media only screen and (max-width: 800px) {
      width:85px;
      left:10px;
  }
   }
`


export default Header