import React,{useEffect, useState,useRef}from 'react'
import './App.css';
import Chat from './chat';
import Header from './Header';
import styled from "styled-components";
import { Us } from 'react-flags-select';
import ReactFlagsSelect from 'react-flags-select';
import {Countries} from './countries'
import logosmall from './logosmall.svg'
import Phone from './Phone.svg'
import { Widget } from '@typeform/embed-react'
import Asset1 from './Asset/Asset1.svg'
import Asset2 from './Asset/Asset2.svg'
import Asset3 from './Asset/Asset3.svg'
import Asset4 from './Asset/Asset4.svg'
import Asset5 from './Asset/Asset5.svg'
import Asset6 from './Asset/Asset6.svg'
import Asset7 from './Asset/Asset7.svg'
import Asset8 from './Asset/Asset8.svg'
import Asset9 from './Asset/Asset9.svg'
import Asset10 from './Asset/Asset10.svg'
import notif from './Asset/notif.svg'
import textus from './textus.svg'
import {authentication} from "./firebase";
import {  RecaptchaVerifier,signInWithPhoneNumber } from "firebase/auth";
import { run } from "tripetto-runner-chat";
import { ChatRunner } from "tripetto-runner-chat";
import Services from "tripetto-services";
import Welcome from './Asset/welcome.svg'
import Errorsvg from './Asset/error.svg'
import NumberError from './Asset/numberError.svg'
import blobright from './Asset/blobright.svg'
import blobleft from './Asset/blobleft.svg'
import blobbottom from './Asset/blobbottom.svg'
import stars from './Asset/stars.svg'
import TagManager from 'react-gtm-module'
import 'html5-device-mockups/dist/device-mockups.min.css';
import { sha256, sha224 } from 'js-sha256';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import Dashboard from './Dashboard';
import { IPhoneX } from 'react-device-mockups';
const tagManagerArgs = {
    gtmId: 'GTM-P38QT4S'
}
TagManager.initialize(tagManagerArgs)
const { definition, styles, l10n, locale, translations, snapshot, attachments, onSubmit, onPause } = Services.init({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibmgrc0hoS0dBeUx3aHdhQ1F0cUh0ZDRmaGcyQzFFRi9OUUo0czllSXVkZz0iLCJkZWZpbml0aW9uIjoiRnl6YmFPbXEvZDB2QklPbUdGMjh3eEhGMENPSll2MW1FME5sWU9xRkd5ST0iLCJ0eXBlIjoiY29sbGVjdCJ9.ygLwGqreOR_Sx41ocGL6RdWuEt9ZgPJgWXkpyD4EDLc" });

function App() {
  const dataInfo= useRef()
 const [ number,setNumber]= useState('')
 const [ adjustedNumber,setAdjNumber]= useState('')
 const [selected, setSelected] = useState('US');
 const [isActive, setisActive] = useState(false);
 const [placeholder,setPlaceholder]= useState('Phone number')
 const [captcha, setCaptcha]=useState(null)
 const [otp,setOTP]=useState(false)
 const [changeSource,setChangeSource] =useState(notif)
 const [triggerAnimation,setAnimation] =useState(false)
 const [user, setUser]=useState(null)
 const [realNumber ,setRealNumber]=useState('')

useEffect(()=>{
  if(window.recaptchaVerifier){
    console.log({adjustedNumber})
    signInWithPhoneNumber(authentication, `+1${adjustedNumber}`, window.recaptchaVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code)
      console.log({confirmationResult})
      window.confirmationResult = confirmationResult;
      setNumber('')
      setAdjNumber('')
      setOTP(true)
      // ...
    }).catch((error) => {
      // Error; SMS not sent
      console.log(error) 
      setTimeout(()=>{   
        setChangeSource(NumberError)
        setAnimation(true)
      },1000)
      setTimeout(()=>{   
        setAnimation(false)
      },3000)
      setisActive(false)
      setPlaceholder('Phone number')
      // ...
    });
  }

},[captcha])
 
useEffect(()=>{
 if(otp){
   if(number.length===6){
    window.confirmationResult.confirm(number).then((result) => {
      // User signed in successfully.
      const user = result.user;
      setUser(user)
      postHandler('CompleteRegistration')
      console.log(user)
      setAnimation(false)
      setTimeout(()=>{
        setChangeSource(Welcome)
        setAnimation(true)
      },1000)
      setTimeout(()=>{
        setAnimation(false)
      },5000)
     

      // ...
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...
      setAnimation(false)
      setTimeout(()=>{   
        setChangeSource(Errorsvg)
        setAnimation(true)
      },1000)
    });
   }
 }
},[otp,number])

 const handleChange=(value)=>{
   if(otp){
     if(number.length<6){
    if(!isNaN(Number(value))){
      setNumber(value)
      setAdjNumber(value)
     }
    }else{
      setNumber(String(value).split('').slice(0,6).join(''))
    }
   }else{
    if(!isNaN(Number(value))){
      setNumber(value)
      setAdjNumber(value)
     }
   }

 }
 const loadCaptcha=()=>{
   let captureLoad =new RecaptchaVerifier('sign-in-button', {
    'size': 'invisible',
    'callback': (response) => {
    }

  }, authentication);

  setCaptcha(captureLoad)
  window.recaptchaVerifier = captureLoad;
}
 const handleJoin=()=>{
  console.log(sha256(number))
  postHandler('Subscribe')
   if(number.length>7){
    setRealNumber(number)
    setisActive(true)
    setAnimation(true)
    setPlaceholder('Enter verification Code')
    setNumber('')
     loadCaptcha()
   }else{
    setAnimation(false)
    setTimeout(()=>{   
      setChangeSource(NumberError)
      setAnimation(true)
    },200)
    setTimeout(()=>{   
      setAnimation(false)
    },3000)
    setisActive(false)
    setPlaceholder('Phone number')
   }


 }
const postHandler= async (type)=>{
  const hashedNumber=`${sha256('1'+String(adjustedNumber))}`
  console.log({hashedNumber})
  const endPoint =`https://graph.facebook.com/v12.0/507941064171265/events?access_token=EAAHKeSZC1Fn8BAAWnOMAsmwABfZCLZAdx2tgBOHpfretrNdMNQVUrbkZC1ecCifYsxbjcyYVoc9bPA2lP9R6tHJYqn9lkDdjN1rGZArtx1O5QzrO1vwUCzJcgSRcEXyJZBcCxNWMrXjvtX1cNWYjCW1BRqL7kDutDJ6PYjZC2z4oIXmj71TtXvoqaGcWxYZCILUZD`
   const payload ={
    "data": [
        {
            "event_name": `${type}`,
            "event_time": `${Date.now()}`,
            "user_data": {
                "ph": [
                  `${hashedNumber}`
                ]
            },
            "custom_data": null
        }
    ] 
}
console.log(payload)
console.log(JSON.stringify(payload))
// axios.post(endPoint, {payload})
// .then(res => {
//   console.log(res);
//   console.log(res.data);
// }).catch((err)=>console.log(err))
// ReactPixel.track(type, payload)
if (typeof window !== "undefined") {
  if (window.fbq != null) { 
    console.log(window.fbq)
    window.fbq('track', type);
  }
}
}
const createContact= async()=>{
  
}

  return (
    <div className="App">
       {/* <Chat/> */}

       <Header/>
      
       <Spacer size="100px"/>
       <H1>Get insane exclusive deals for clothes</H1>
       <H1> you’ll actually want to wear </H1>
       <Spacer size="20px"/>
       <H2>Get personalized recommendations tailored to your style preference </H2>
       <H2> Delivered daily to your text inbox </H2>
   {   !user&& <div>
         <PhoneInput active={isActive}>
         <Flag active={isActive}>
    <ReactFlagsSelect
      selected={selected}
      onSelect={code => setSelected(code)}
      customLabels={{
        ...Countries,
        "US": {secondary: "+1" },
        "CA": {secondary: "+1" },
      }}
    />
      </Flag>
         <input
        containerClassName="intl-tel-input2"
        className="form-control2"
        placeholder={placeholder}
        value={number}
         onChange={(e)=>{handleChange(e.target.value)}}
         />
         <Join active={isActive}onClick={handleJoin}><p>Join</p></Join>
        </PhoneInput>
        <Blur/>
       </div>}
       <div id='sign-in-button'></div>
      
       <PhoneSection>
         {/* <img src={Phone}/> */}
         <p className='notice'>Joining means you agree to our Terms and Privacy Policy.</p>
       </PhoneSection>

        <div className="iphone-x">
  <i>Speaker</i>
  <b>Camera</b>
  <Notif trigger={triggerAnimation} active={isActive}className='notif'src={changeSource}/>
         <PhoneMask ref={dataInfo}>
           {/* <div id="data-center"></div> */}
           {user&&<Trip src={`https://tripetto.app/run/4TRGQEZQ66?number=+1${realNumber}`}></Trip>}
          {!user&& <Chat/> }
         </PhoneMask>
</div>
       <div className="grid">
         <div className="gridItem">Personalized style quiz</div>
         <div className="gridItem">Daily deals from thousands of stores</div>
         <div className="gridItem">Insane discounts valid for 24 hours</div>
         <div className="gridItem">First dibs on exclusive fashion drops</div>
       </div>
       <footer>
      <div style={{zIndex:30}}><img src={logosmall}/>
      {/* <img src={textus}/> */}
      </div>
      <div style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
        <p>© Copyright 2021, All Rights Reserved</p>
        <p> <a href='https://joinsugar.legalsites.app/'>Legal</a></p>
      </div>
      <div  className="contact">hello@joinsugar.co</div>
    
       </footer>
       <img className='asset1 asset'src={Asset1}/>
       <img className='asset2 asset'src={Asset2}/>
       <img className='asset3 asset'src={Asset3}/>
       <img className='asset4 asset'src={Asset4}/>
       <img className='asset5 asset'src={Asset5}/>
       <img className='asset6 asset'src={Asset6}/>
       <img className='asset7 asset'src={Asset7}/>
       <img className='asset8 asset'src={Asset8}/>
       <img className='asset9 asset'src={Asset9}/>
       <img className='asset10 asset'src={Asset10}/>
       
       <BlobRight src={blobright}/>
       <BlobLeft src={blobleft}/>
       <BlobBottom src={blobbottom}/>
       <Stars src={stars}/>

    </div>
  );
}
const H1 = styled.h1`
position:relative;
 z-index:20;
 /* width:50%; */
 margin:0px auto;
 font-family:Recolata-Medium;
font-style: bold;
font-weight: 500;
font-size: 3.5em;
line-height: 68px;
text-align: center;
color: #FFFFFF;
@media only screen and (max-width: 900px) {
  font-size: 1.5em;
  line-height: 38px;
}
@media only screen and (max-width: 560px) {
  font-family: Recolata-Medium;
  font-size: 22px;
  line-height: 25px;

}
}
`
const H2 = styled.h2`
 font-family: Recolata-Regular;
 margin:0px auto;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
text-align: center;
position:relative;
 z-index:20;
color: #FFFFFF;
@media only screen and (max-width: 900px) {
  font-size: 1em;
  line-height: 20px;
}
@media only screen and (max-width: 560px) {
  font-size: 11px;
  line-height: 20px;
}
}`

const Blur =styled.div`
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    90deg,
    #44ff9a -0.55%,
    #44b0ff 22.86%,
    #8b44ff 48.36%,
    #ff6644 73.33%,
    #ebff70 99.34%
  );
  filter: blur(43.871px);
  width: 500px;
  height: 50px;
  margin-top: -100px;
  position: relative;
  z-index: 10;
  @media only screen and (max-width: 900px) {
  width:300px;
  height: 30px;
  font-size:15px;
  margin-top: -90px;
  filter: blur(30px);
}
`
const PhoneInput = styled.div`
  position: relative;
  z-index: 20;
 display:flex;
 justify-content:center;
 align-items:center;
width:700px;
margin:20px auto;
padding:20px;

&>input{
  padding-left:10px;
  height:64px;
 font-size:25px;
 text-align:${props => props?.active?"center":'left'};
 border-radius:${props => props?.active?"15px":'0px'};
 ::placeholder,
  ::-webkit-input-placeholder {
    font-size:25px;
    font-family: Recolata-Regular;
  }
 @media only screen and (max-width: 900px) {
  width:300px;
  height:48px;
  font-size:16px;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size:16px;
    font-family: Recolata-Regular;
  }
}
}
@media only screen and (max-width: 900px) {
  width:300px;

}

`
const Join=styled.div`
  position: relative;
  z-index: 20;
background: #28887E;
height: 68px;
width:100px;
display:${props => props?.active?"none":'flex'};
align-items:center;
justify-content:center;
border-bottom-right-radius: 10px;
border-top-right-radius: 10px;
font-size:30px;
color:white;
font-family: Recolata-Regular;
cursor:pointer;
@media only screen and (max-width: 900px) {
  width:50px;
  font-size:20px;
  padding:10px;
  height: 35px;
}
`
const Flag =styled.div`
position: relative;
z-index: 20;
background: #24243a;
height: 70px;
width:fit-content;
display:flex;
align-items:center;
justify-content:center;
border-bottom-left-radius: 10px;
border-top-left-radius: 10px;
display:${props => props?.active?"none":'flex'};
@media only screen and (max-width: 900px) {
  height: 60px;
}
`
const PhoneSection=styled.div`
position: relative;
z-index: 20;
width:100%;
/* height:95vh; */
display:flex;
justify-content:center;
margin-top:100px;

@media only screen and (max-width: 900px) {
  margin-top:70px;
}
`
const PhoneMask = styled.div`
/* position:absolute; */
overflow-y:scroll;
overflow-x:hidden;
height:100%;
width:100%;
background:white;
background:#25253b;
top:45px;
&::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 1500px) {
  top:40px;
  border-radius:35px;
  width:360px;
}
`
const Spacer= styled.div`
margin-bottom:${props => props?.size || "50px"};
@media only screen and (max-width: 900px) {
}
`
const Notif =styled.img` 
 position:absolute;
 z-index:30;
 width:400px;
 transition ease-in-out 0.3s;
 transform:${props => props?.trigger?"scale(1);":'scale(0);'};
 @media only screen and (max-width: 1500px) {
  width:360px;
}
`
const BlobRight =styled.img`
position:absolute;
top:0px;
right:0px;
width:1000px;
@media only screen and (max-width: 600px) {
  /* display:none; */
  width:400px;
}
`
const BlobLeft =styled.img`
position:absolute;
top:0px;
left:0px;
height:1700px;
@media only screen and (max-width: 600px) {
  display:none;
}
`

const BlobBottom =styled.img`
position:absolute;
display:none;
right:0px
z-index:1;
width:100%;
`
const Stars=styled.img`
position:absolute;
top:20px;
width:100%;
@media only screen and (max-width: 600px) {
}
`
const Trip =styled.iframe`
width:100%;
height:100%;
`
export default App;
